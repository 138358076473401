import * as Sentry from '@sentry/vue';

export default ({ app, router }) => {
    Sentry.init({
        app,
        dsn: 'https://3f3e725f7d69cf5a7e97101ee7fe1170@sentry.artes-cloud.ru/2',
        environment: 'develop',
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
    
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['etabl.ru', 'e-tabl.ru'],

        allowUrls: ['localhost','etabl.ru', 'e-tabl.ru'], 
    
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};

import { request } from 'src/boot/axios';
import { LocalStorage } from 'quasar';

async function GET_BASKET(context) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets`); 

    context.commit('SET_BASKET', result);
};

async function GET_DELIVERY_BASKET(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
        query: {
            variantType: payload || null,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/from/courier?`); 

    context.commit('SET_BASKET', result);
};

async function GET_PICKUP_BASKET(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
        query: {
            variantType: payload || null,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/from/pickup`); 

    context.commit('SET_BASKET', result);
};

async function BASKET_GET_DELIVERY_CITIES(context) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('get', 'delivery/cities', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
    });
};

async function PUT_PRODUCT_IN_BUSKET(context, {product}) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('put', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/products/${product.id}?count=${product.amount}&isChecked=${product.checked}`); ;
    



    context.commit('SET_BASKET_COUNT', result.numberOfPackages);
    context.commit('SET_BASKET_PRODUCTS', result.items);

    return result;
};

async function DELETE_PRODUCT_FROM_BASKET(context, {id}) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('delete', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/products/${id}`); ;
    
    context.commit('SET_BASKET_COUNT', result.numberOfPackages);
    context.commit('SET_BASKET_PRODUCTS', result.items);

    return result;
};

async function GET_PICKUP_INVENTORIES(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
        query: {
            variantType: payload || null,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/from/pickup/inventories`); 

    context.commit('SET_INVENTORIES', result);
};

async function SET_DRUGSTORE(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('put', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/from/pickup/pharmacy?pharmacyId=${payload.id}&variantType=${payload.variant}`); 

    context.commit('SET_BASKET', result);
};

async function DELETE_DRUGSTORE(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('delete', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/from/pickup/pharmacy?variantType=${payload.variant}`); 

    context.commit('SET_BASKET', result);
};

async function SET_PRODUCT_CHECK(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('put', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            isChecked: payload.isChecked,
            wayToGetType: payload.variant || null,
            variantType: payload.date || null,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/checks/${payload.id}`); 

    context.commit('SET_BASKET', result); 
};

async function SET_PRODUCTS_CHECKS(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('put', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            isChecked: payload.isChecked,
            wayToGetType: payload.variant || null,
            variantType: payload.date || null,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/checks`, payload.products); 

    context.commit('SET_BASKET', result); 
};

async function CHANGE_PRODUCT_AMOUNT(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('put', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            count: payload.count,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/products/${payload.id}`); 
};

async function CHANGE_BASKET_PRODUCT_AMOUNT(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            count: payload.count,
            wayToGetType: payload.variant || null,
            variantType: payload.date || null,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/products/${payload.id}/count`); 

    context.commit('SET_BASKET', result);
    context.commit('SET_BASKET_COUNT', result.preCheck.totalPackages);

    if(result.blocks.length)
        context.commit('SET_BASKET_PRODUCTS', result.blocks[0].items);

};

async function GET_PRODUCTS_IN_BASKET(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/products`); 

    context.commit('SET_BASKET_COUNT', result.numberOfPackages);
    context.commit('SET_BASKET_PRODUCTS', result.items);

    return result;
};

async function DELETE_CHECKED_PRODUCTS(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('delete', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            wayToGetType: payload.variant || null,
            variantType: payload.date || null,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/products`, payload.products); 

    context.commit('SET_BASKET', result); 
    context.commit('SET_BASKET_COUNT', result.preCheck.totalPackages);
    
    if(result.blocks.length)
        context.commit('SET_BASKET_PRODUCTS', result.blocks[0].items);
};

async function GET_ADDRESS_SUGGEST(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            query: payload.query,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/addresses/suggestions`); 

    return result;
}

async function SAVE_ADDRESS(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data,
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/addresses`);
}

async function GET_ADDRESSES(context) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/addresses?page=1&size=20`);

    context.commit('SET_ADDRESSES', result);

    return result;
}

async function DELETE_ADDRESS(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('delete', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/addresses/${payload}`);
}

async function GET_ADDRES_BY_ID(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/addresses/${payload}`);

    return result;
}

async function EDIT_ADDRESS(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('put', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data: payload.data,
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/addresses/${payload.id}`);
}

async function GET_PROMOCODES(context) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    try {
        let result = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.rootState.user.token,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/promo-codes?page=1&size=10`);
    
        context.commit('SET_USER_PROMOCODES', result.results);
    
        return result;
    } catch (error) {
        return void console.error(error);
    }
    
}

async function SET_PROMOCODE(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data: payload.data,
        query: {
            wayToGetType: payload.variant || null,
            variantType: payload.date || null,
            promoCodeId: payload.promoCodeId || null,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/promo-code`);

    context.commit('SET_BASKET', result);
    context.commit('SET_BASKET_COUNT', result.preCheck.totalPackages);
}

async function FIND_PROMOCODE(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/promo-codes/by-code/${payload}`);

    return result;

}

async function CREATE_ORDER(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
        data: data.data,
        query: {
            utm_source: data.utm_source,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders`);

    LocalStorage.remove('utm_source');

    return result;
}

async function SET_RECIPE(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
        query: {
            wayToGetType: data.way,
            variantType: data.variant,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/recipe/${data.id}`);

    context.commit('SET_BASKET', result);

    return result;
}

async function PUT_PRODUCTS_IN_BUSKET(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('put', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
        query: {
            is_packaging: payload.isPackaging || false,
            wayToGetType: payload.way || null,
            variantType: payload.variant || null,
            is_reordering: payload.isReordering || null,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/products`, payload.data);

    context.commit('SET_BASKET', result);

    return result;
};

async function REPLACE_BASKET_PRODUCTS(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
        data: payload.data,
        query: {
            wayToGetType: payload.way || null,
            variantType: payload.variant || null,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/baskets/products/replace`); 

    context.commit('SET_BASKET', result);
    context.commit('SET_BASKET_COUNT', result.preCheck.totalPackages);

    if(result.blocks.length)
        context.commit('SET_BASKET_PRODUCTS', result.blocks[0].items);

};

export {
    BASKET_GET_DELIVERY_CITIES,
    PUT_PRODUCT_IN_BUSKET,
    GET_BASKET,
    GET_DELIVERY_BASKET,
    GET_PICKUP_BASKET,
    GET_PICKUP_INVENTORIES,
    SET_DRUGSTORE,
    SET_PRODUCT_CHECK,
    SET_PRODUCTS_CHECKS,
    CHANGE_PRODUCT_AMOUNT,
    CHANGE_BASKET_PRODUCT_AMOUNT,
    GET_PRODUCTS_IN_BASKET,
    DELETE_CHECKED_PRODUCTS,
    GET_ADDRESS_SUGGEST,
    SAVE_ADDRESS,
    GET_ADDRESSES,
    DELETE_ADDRESS,
    GET_ADDRES_BY_ID,
    EDIT_ADDRESS,
    DELETE_PRODUCT_FROM_BASKET,
    GET_PROMOCODES,
    SET_PROMOCODE,
    FIND_PROMOCODE,
    CREATE_ORDER,
    DELETE_DRUGSTORE,
    SET_RECIPE,
    PUT_PRODUCTS_IN_BUSKET,
    REPLACE_BASKET_PRODUCTS,
};
